<template>
  <div class="PageBox" :class="$i18n.locale">
    <div class="bgc" :class="[bgColor ? 'bgColor' : '']">
      <div class="topTitle">{{ $t("promote[0]") }}</div>
    </div>
    <div class="my_title" ref="content">
      <div :class="[Language != 'cn' ? 'title_vn' : '']">
        {{ $t("promote[1]") }}
      </div>
    </div>
    <div class="ScrollBox">
      <div class="PromoteInfo">
        <div class="one" style="padding: 15px 12px">
          <div id="QRCode"></div>
          <h4 class="m0">
            {{ $t("promote[2]") }}：<span id="IosCode">{{
              UserInfo.idcode
            }}</span>
          </h4>
          <input
            id="AppCode"
            type="text"
            readonly
            :value="UserInfo.idcode"
            style="position: absolute; opacity: 0"
          />
          <div class="copy">
            <input type="text" :value="promoteUrl" id="IosLink" />
            <input
              id="AppLink"
              type="text"
              readonly
              :value="promoteUrl"
              style="position: absolute; opacity: 0"
            />
          </div>
          <a
            class="a_one"
            href="javascript:;"
            @click="$Util.CopyText('IosLink', 'AppLink')"
          >
            {{ $t("promote[3]") }}</a
          >
        </div>
        <div class="foot1">
          <div class="foot2">
            {{ $t("promote[4]") }}
          </div>
        </div>
        <div class="invite">
          <div>
            <div class="a" style="margin-top: 30px">{{ $t("promote[5]") }}</div>
            <div id="demo-custom" class="first"></div>
          </div>
          <div class="first">
            <!--            <div class="a">{{ $t("promote[5]") }}</div>-->
            <!--            <div class="facebook" @click="share('facebook')"></div>-->
            <!--            <div class="twitter" @click="share('twitter')"></div>-->
            <!--            <div class="whatsapp" @click="share('whatsapp')"></div>-->
            <!--            <div class="c" @click="share('message')"></div>-->
            <!--            <div class="tg" @click="share('telegram')"></div>-->
          </div>
          <div class="second"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import "@/assets/css/test.css";
import platform from "@/share/js/platform/platform";
import digg from "@/share/js/platform/digg";
import facebook from "@/share/js/platform/facebook";
import tumblr from "@/share/js/platform/tumblr";
import reddit from "@/share/js/platform/reddit";
import telegram from "@/share/js/platform/telegram";
import linkin from "@/share/js/platform/linkin";
import pinterest from "@/share/js/platform/pinterest";
import googlePlus from "@/share/js/platform/googlePlus";
import twitter from "@/share/js/platform/twitter";
import whatsapp from "@/share/js/platform/whatsapp";
import twitterFollow from "@/share/js/platform/twitterFollow";
import sharrre from "@/share/js/jquery.sharrre";
import $ from "jquery";
export default {
  name: "Promote",
  components: {},
  props: ["isReward"],
  data() {
    return {
      promoteUrl: "",
      saveN: 0,
      isPlus: window.plus,
      showReward: false,
      docTitle: document.title,
      head: false,
      bgColor: false,
      Language: localStorage["Language"] ? localStorage["Language"] : "", //语言
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.isReward) {
      this.showReward = true;
    }
    this.$parent.navBarTitle = "";
    // 改路径
    // this.promoteUrl = `${localStorage["CurrLine"]}/#/user/promotekid/${this.UserInfo.idcode}`;
    this.promoteUrl = `https://happyjob.online/#/user/promotekid/${this.UserInfo.idcode}`;
  },
  mounted() {
    new QRCode(document.getElementById("QRCode"), {
      text: this.promoteUrl,
      width: 110,
      height: 110,
      correctLevel: QRCode.CorrectLevel.H,
    });
    this.pageScroll();
    this.loadShareButton();
  },
  activated() {},
  destroyed() {
    // 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    saveQRCode() {
      if (this.isPlus) {
        this.saveN += 1;
        var img = $("#QRCode")
          .find("img")
          .attr("src")
          .replace("data:image/png;base64,", "");
        var bm = new plus.nativeObj.Bitmap();
        bm.loadBase64Data(
          img,
          () => {
            bm.save(
              "_doc/promote" + this.saveN + ".jpg",
              {
                overwrite: true,
                format: "jpg",
              },
              (e) => {
                plus.gallery.save(
                  e.target,
                  (e) => {
                    this.$Dialog.Toast(this.$t("promote[7]"));
                  },
                  (err) => {
                    this.$Dialog.Toast(this.$t("promote[8]"));
                  }
                );
              },
              (error) => {
                this.$Dialog.Toast(this.$t("promote[8]"));
              }
            );
            setTimeout(function () {
              bm.recycle();
            }, 1000);
          },
          (err) => {
            this.$Dialog.Toast(this.$t("promote[8]"));
          }
        );
      } else {
        this.$Dialog.Toast(this.$t("promote[9]"));
      }
    },
    turn() {
      console.log(document.documentElement.scrollTop);
    },
    pageScroll() {
      // 监听滚动事件，用handleScroll这个方法进行相应的处理
      window.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      let top = this.$refs.content.getBoundingClientRect().top;
      // 监听到页面滚动后的处理事件
      if (top > -1) {
        this.bgColor = false;
      } else {
        this.bgColor = true;
      }
    },
    loadShareButton() {
      let url_myPage =
        localStorage["CurrLine"] + `/#/user/promotekid/${this.UserInfo.idcode}`;

      var list = [
        "facebook",
        "twitter",
        "whatsapp",
        "tumblr",
        "telegram",
        "reddit",
        "pinterest",
        "googlePlus",
      ];
      var tt = $("#demo-custom");
      tt.sharrre({
        share: {
          googlePlus: true,
          facebook: true,
          twitter: true,
          telegram: true,
          pinterest: true,
          linkin: true,
          tumblr: true,
          whatsapp: true,
          reddit: true,
          twitterFollow: true,
          delicious: true,
          stumbleupon: true,
          linkedin: true,
        },
        buttons: {
          facebook: {
            popup: {
              width: 1200,
              height: 900,
            },
          },
          twitter: {},
          delicious: {},
          linkedin: {},
          pinterest: {},
          twitterFollow: {
            user: "GarethBale11",
          },
        },
        url: url_myPage,
        text: "share",
        enableCounter: false,
        enableHover: false,
        template: (function () {
          var content = "";
          for (var i in list) {
            var service = list[i];
            content += '<div class="' + service + '"></div> ';
            // if (i%5==0){
            //   content += '<span style="t"></span>'
            // }
          }
          return content;
        })(),
        render: function (api, options) {
          for (var i in list) {
            var service = list[i];
            $(api.element).on("click", "." + service, function () {
              api.openPopup(this.className);
            });
          }
        },
      });
    },
    // 分享到。。。
    share(type) {
      let url_myPage =
        localStorage["CurrLine"] + `/#/user/promotekid/${this.UserInfo.idcode}`;
      let url = "";
      switch (type) {
        case "facebook":
          url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
            url_myPage
          )}`;
          break;
        case "twitter":
          url = `http://twitter.com/share?url=${encodeURIComponent(
            url_myPage
          )}`;
          break;
        case "linkin":
          url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            url_myPage
          )}&title=Hello%20World&summary=12312`;
          break;
        // case "instagram":
        //     url = `https://www.instagram.com/aiaindonesia/?hl=en`;
        //     break
        case "whatsapp":
          url = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            url_myPage
          )}&url=${encodeURIComponent(url_myPage)}`;
          break;
        case "email":
          url = `mailto:?subject=Life%20Insurance%20Advisor%20-%20AIA%20Indonesia&body=${encodeURIComponent(
            shareUrl
          )}`;
          break;
        case "messenger":
          url = `fb-messenger://share/?link=${encodeURIComponent(shareUrl)}`;
          break;
        case "viber":
          url = `viber://forward?text=${encodeURIComponent(shareUrl)}`;
          break;
        case "message":
          var u = navigator.userAgent;
          //判断是否为android终端
          var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
          //判断是否为ios终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
          //获取要分享的端口号
          var tel = "";
          //这里填写要分享的内容
          var des = encodeURIComponent(shareUrl);
          //ios终端
          if (isiOS) {
            url = "sms:" + tel + "&body=" + des;
            //安卓终端
          } else {
            url = "sms:" + tel + "?body=" + des;
          }
          break;
      }
      console.log(url);
      window.open(url, "_blank");
    },
  },
};
</script>
<style scoped>
.PageBox {
  overflow: auto;
  background: url("../../../public/static/images/promote_bg.png") no-repeat 0px;
  background-size: cover;
}
.PageBox .bgc {
  width: 100%;
  background-color: transparent;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.bgColor {
  background-color: #4e51bf !important;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.height {
  height: 46px;
}
.PageBox.en-US {
  background-image: url("../../../public/static/images/promote_bg.png");
}

.PageBox.zh-HK {
  background-image: url("../../../public/static/images/share-zh-HK.png");
}

.PageBox.vi-VN {
  background-image: url("../../../public/static/images/share-vi-VN.png");
}

.PromoteInfo {
  color: #eee;
  text-align: center;
}

.head {
  position: fixed;
  top: 0;
  color: #fff;
  font-size: 18px;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 46px;
  background: #4e51bf !important;
}

.headson {
  position: fixed;
  top: 0;
  color: #fff;
  font-size: 18px;
  width: 100%;
  height: 46px;
  text-align: center;
  line-height: 40px;
}

.PromoteInfo h4 {
  margin-bottom: 10px;
  white-space: nowrap;
}

.a_one {
  color: #fff;
  display: inline-block;
  background: #4e51bf;
  font-size: 13px;
  width: 40px;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  position: absolute;
  top: 223px;
  left: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.PromoteInfo #IosLink {
  word-break: break-all;
  text-align: left;
  display: block;
  text-align: center;
  height: 25px;
  width: 165px;
  background-image: linear-gradient(to right, #fee8ce, #b4b5fb);
  color: #000;
  border: none;
  padding: 5px;
}

.ScrollBox {
  position: relative;
  z-index: 9;
  top: -2px;
}

#QRCode >>> img {
  margin: 6px auto 16px 6.5px;
  display: block;
  border: 5px #fff solid;
  background: #fff;
  border-radius: 5px;
  width: 150px;
}

.my_title {
  background: url("../../../public/static/images/title.png") no-repeat;
  background-size: 100%;
  background-position-x: -1.5px;
  width: 320px;
  height: 110px;
  color: #4e51bf;
  font-weight: 600;
  font-size: 45px;
  font-style: italic;
  line-height: 100px;
  text-align: center;
  margin: auto;
}

.my_title div {
  margin-left: -12px;
  padding-top: 2px;
}

#QRCode {
  width: 164px;
  height: 164px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom, #fd92a4, #5052bf);
  border: 1px solid #3c24a4;
  margin: auto;
}

.m0 {
  color: #000;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
}

#IosCode {
  font-size: 16px;
}

.one {
  background: rgb(243, 245, 255);
  width: 242px;
  height: 270px;
  margin: auto;
  position: relative;
  z-index: 999;
}

.foot1 {
  width: 337px;
  padding: 8px;
  border-radius: 15px;
  background-image: linear-gradient(to bottom, #5b56bd, #f790a5);
  margin: auto;
}

.foot2 {
  background: #b4b5fb;
  width: 320px;
  padding: 10px 30px;
  color: #3c24a4;
  border-radius: 15px;
  background-image: linear-gradient(to right, #fee9ce, #b4b5fc);
}

.invite {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  max-width: 490px;
  margin: 0 auto;
}

.invite .first {
  width: 60%;
  height: 40px;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
}

.invite .second {
  margin: auto;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.first div {
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #fff;
  border-radius: 40%;
  margin-left: 10px;
}

.second div {
  min-width: 50px;
  min-height: 50px;
  background-color: #fff;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: 10px;
}

.a {
  background: rgba(0, 0, 0, 0) !important;
  min-width: 90px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wa {
  background-image: url("../../../public/static/icon2/icon_whatsapp_press.png");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 7px;
}

.fb {
  background-image: url("../../../public/static/icon2/facebook.png");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 7px;
}

.wx {
  background-image: url("../../../public/static/icon2/wx.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 10px;
}

.e {
  background-image: url("../../../public/static/icon2/youtube.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 9px;
}

.f {
  background-image: url("../../../public/static/icon2/qq.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 9px;
}

.tg {
  background-image: url("../../../public/static/icon2/telegram.png");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 7px;
}

.tw {
  background-image: url("../../../public/static/icon2/twitter.jpeg");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 7px;
}
.title_vn {
  line-height: inherit;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  padding-top: 0px;
  font-size: 17px;
}
</style>
